
const {REACT_APP_BASE_URL: BASE_URL} = process.env;

const tabTypeItems = ["road_pits", "path_signs"];

const tabStatusItems = ["new", "in_work", "in_moderation", "repaired"];

const almatyCoordinates = {
    latitude: 43.2567,
    longitude: 76.9286
}

const astanaCoordinates = {
    latitude: 51.139392,
    longitude: 71.439074
}

export {
    tabTypeItems,
    BASE_URL,
    tabStatusItems,
    almatyCoordinates,
    astanaCoordinates
}