import React, {Fragment, useContext, useEffect, useState} from "react";
import "leaflet/dist/leaflet.css";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import {Box, Button, Modal, Typography} from "@mui/material";
import {DataGrid, GridSingleSelectColDef} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import {Link, useSearchParams} from "react-router-dom";
import {Icon, divIcon, point} from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import dayjs from "dayjs";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {AuthContext} from "../../context/AuthContext";
import {Row} from "../../services/common-types";
import {changePitStatus, getRoadPits} from "./services/fetchers";
import MapPinIcon from "../../assets/icons/map-pin";
import ListIcon from "../../assets/icons/list";
import useFilter from "../../hooks/useFilter";
import RenderCell from "../../components/RenderCell";
import MarkerRedIcon from "../../assets/icons/marker-red.svg";
import MarkerBlueIcon from "../../assets/icons/marker-blue.svg";
import MarkerYellowIcon from "../../assets/icons/marker-yellow.svg";
import MarkerPedestrianIcon from "../../assets/icons/marker-pedestrian.svg";
import "../../assets/styles/map.css";
import {ReactComponent as PinOrange} from "../../assets/icons/pin-orange.svg";
import {ReactComponent as PinGreen} from "../../assets/icons/pin-green.svg";
import {ReactComponent as PinRed} from "../../assets/icons/pin-red.svg";
import FileInputField from "../../UI/InputFields/FileInputField";
import CustomButton from "../../UI/CustomButton";
import Filters from "../../components/Filters";
import {almatyCoordinates, astanaCoordinates, BASE_URL, tabTypeItems} from "../../services/constants";

import("dayjs/locale/ru");

dayjs.locale("ru");

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "4px",
    boxShadow: 24,
    p: 4,
};

const tabItems = [
    {
        Icon: MapPinIcon,
        label: "map"
    },
    {
        Icon: ListIcon,
        label: "list"
    }
];

// const tabTypeItems = ["Дорожные ямы", "Знаки движения"];

const markerRedIcon = new Icon({
    iconUrl: MarkerRedIcon,
    iconSize: [48, 48]
})

const markerBlueIcon = new Icon({
    iconUrl: MarkerBlueIcon,
    iconSize: [48, 48]
})

const markerYellowIcon = new Icon({
    iconUrl: MarkerYellowIcon,
    iconSize: [48, 48]
})

const markerPedestrianIcon = new Icon({
    iconUrl: MarkerPedestrianIcon,
    iconSize: [48, 48]
})

const RoadPits = () => {
    const [markers, setMarkers] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [stepMode, setStepMode] = useState(searchParams.has("mode") ? tabItems.findIndex(tab => tab.label === searchParams.get("mode")) : 0);
    const stepType = searchParams.has("type") ? tabTypeItems.findIndex(tab => tab === searchParams.get("type")) : 0
    const { t } = useTranslation();
    const {setNotification} = useContext(AuthContext);
    const [rows, setRows] = useState<Row[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [columns, setColumns] = useState<GridSingleSelectColDef[]>([]);
    const [tempPit, setTempPit] = useState<{id?: number, status?: string}>({});
    const [open, setOpen] = useState(false);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [reFetch, setReFetch] = useState(false);
    const isMobile = window.innerWidth < 768 && stepMode === 1;

    const { filters, handleSelectChange, handleCheckboxChange, handleChangeMobile, resetFilters, statuses, levels, regions } = useFilter();

    const headers = [
        {
            key: "id",
            label: t("id")
        },
        {
            key: "image",
            sortable: false,
            label: "Фото",
            flex: 0,
            width: 100,
            renderCell: (cellParams: any) => <RenderCell columnId="image" cellParams={cellParams} />
        },
        {
            key: "coordinates",
            label: t("coordinates"),
            renderCell: (cellParams: any) => <RenderCell columnId="coordinates" cellParams={cellParams} />
        },
        {
            key: "status",
            label: t("status"),
            renderCell: (cellParams: any) => <RenderCell columnId="status" cellParams={cellParams} />
        },
        {
            key: "level",
            label: t("level"), // Тип
            renderCell: (cellParams: any) => <RenderCell columnId="level" cellParams={cellParams} />
        },
        {
            key: "created_at",
            label: t("created_at"),
            renderCell: (cellParams: any) => <RenderCell columnId="date" cellParams={cellParams} />
        }
    ]

    const getItems = async () => {
        try {
            setIsLoading(true);
            const rows = await getRoadPits(filters, tabTypeItems[stepType], isMobile);
            setReFetch(false);
            if (!isMobile) {
                setMarkers(rows);
                const cols = headers.map((col: any) => ({
                    ...col,
                    field: col.key,
                    headerName: col.label,
                    flex: col.flex ?? (col.key === "id" ? 0 : 1),
                    width: col.width || (col.key === "id" ? 50 : 125)
                }))
                setColumns(cols);
            }
            setRows(rows);
            // @ts-ignore
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (reFetch) {
            (async () => {
                try {
                    const res = await getRoadPits(filters, tabTypeItems[stepType]);
                    setMarkers(res);
                    setReFetch(false);
                } catch (e) {
                    console.error("error", e);
                }
            })()
        }
    }, [reFetch])

    const handleChangeMode = (value: number) => {
        setStepMode(value);
        setSearchParams(params => {
            params.set("mode", tabItems[value].label);
            return params;
        });
    }

    useEffect(() => {
        getItems();
    }, [filters, stepType, stepMode])

    const handleClose = () => {
        setOpen(false);
    }

    const submitFileAndChangeStatus = () => {
        handleClose();
        onFixedHandler(tempPit.id as number, tempPit.status as string, true);
        setImageFile(null);
    }

    const openFileInputModal = () => {
        setOpen(true);
    }

    const onFixedHandler = async (id: number, status: string, afterModal=false) => {
        if (!afterModal && status === "В работе") {
            openFileInputModal();
            setTempPit({id, status});
            return null;
        }
        try {
            const newStatus = status === "Ожидает"
                ? "В работе"
                : status === "В работе"
                    ? "На проверке"
                    : "Исправили"
            await changePitStatus(id, newStatus, imageFile);
            setTempPit({});
            setReFetch(true);
            setNotification({
                open: true,
                type: "success",
                message: t("status_changed_successfully")
            })
        } catch (e) {
            setNotification({
                open: true,
                type: "error",
                message: t("status_change_failed")
            })
        }
        return null;
    }

    const getIconByLevel = (level="Маленькая яма") => {
        switch (level) {
            case "Маленькая яма":
                return markerBlueIcon;
            case "Средняя яма":
                return markerYellowIcon;
            case "Большая яма":
                return markerRedIcon;
            case "Разметка пешехода стирается":
                return markerPedestrianIcon
            default:
                return markerBlueIcon;
        }
    }

    const getButtonLabel = (status="Ожидает") => {
        switch (status) {
            case "Ожидает":
                return "to_work"
            case "Исправили":
                return ""
            case "В работе":
                return "fixed"
            default:
                return "to_work"
        }
    }

    const createClusterCustomIcon = (cluster: any) => divIcon({
        html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
        className: "custom-marker-cluster",
        iconSize: point(33, 33, true)
    });

    const mode = searchParams.get("mode") || "map";
    const {latitude, longitude} = process.env.REACT_APP_CITY === "almaty" ? almatyCoordinates : astanaCoordinates;
    return (
        <Box>
            <Box sx={{display: {xs: "none", md: "flex"}, justifyContent: "space-between", alignItems: "center", mb: {xs: "1rem", md: "2rem"}}}>
                <Typography variant="h1" sx={{fontSize: {xs: "1.5rem", md: "2rem"}, fontWeight: "700"}}>{t("pits")}</Typography>
            </Box>
            <Box sx={{display: "flex", mb: "1rem", p: {xs: "0 1rem", md: "0"}}}>
                { tabItems.map((item, index) => (
                    <Box
                        onClick={() => handleChangeMode(index)}
                        key={index} sx={{backgroundColor: index === stepMode ? "brandColor.primary" : "white",
                            width: {xs: "100%", md: "230px"}, display: "flex", justifyContent: "center", alignItems: "center", gap: "8px", p: {xs: ".5rem 1rem", md: "1rem"},
                            borderTopLeftRadius: index === 0 ? ".5rem" : "0",
                            borderBottomLeftRadius: index === 0 ? ".5rem" : "0",
                            borderTopRightRadius: index === 0 ? "0" : ".5rem",
                            borderBottomRightRadius: index === 0 ? "0" : ".5rem",
                            boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer"
                        }}
                    >
                        <item.Icon size="16" color={index === stepMode ? "white" : "gray"} />
                        <Typography sx={{color: index === stepMode ? "white" : "text.secondary",
                            fontSize: "14px", fontWeight: "500"}}
                        >
                            {t(item.label)}
                        </Typography>
                    </Box>
                ))}
            </Box>
            <Box sx={{p: {xs: ".25rem 1rem 1rem 1rem", md: ".25rem 1.5rem 1.5rem 1.5rem"}, borderRadius: ".5rem", background: "#fff"}}>
                <Filters exclude={["date", "pits_signs_gen"]} filters={filters} handleChangeMobile={handleChangeMobile} regions={regions} statuses={statuses} levels={levels}
                    handleSelectChange={handleSelectChange} handleCheckboxChange={handleCheckboxChange}
                />
                <Box sx={{width: "100%", minHeight: "100%", height: "100%", marginTop: "1.5rem"}}>
                    { mode === "map"
                        ? (
                            <>
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={modalStyle}>
                                        <Typography sx={{textAlign: "center", mb: "1rem"}} id="modal-modal-title" variant="h6" component="h2">
                                            {t("add_photo")}
                                        </Typography>
                                        <FileInputField label="" value={imageFile} onChange={value => setImageFile(value)} />
                                        <CustomButton label={t("submit")} onChange={submitFileAndChangeStatus} style={{width: "100%", marginTop: "1rem"}} />
                                    </Box>
                                </Modal>
                                <MapContainer
                                    className="map-container"
                                    center={[latitude, longitude]}
                                    zoom={13}
                                >
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <MarkerClusterGroup
                                        chunkedLoading
                                        iconCreateFunction={createClusterCustomIcon}
                                    >
                                        { markers.map((marker: any, index: number) => (
                                            <Marker key={index} position={[parseFloat(marker.latitude), parseFloat(marker.longitude)]} icon={getIconByLevel(marker.level)}>
                                                <Popup>
                                                    <Box sx={{display: "flex", flexDirection: {xs: "column", md: "row"}, gap: ".75rem"}}>
                                                        <Box sx={{position: "relative", width: {xs: "100%", md: "180px"}, height: {xs: "150px", md: "180px"}, borderRadius: "4px", overflow: "hidden"}}>
                                                            <Link to={marker.image} target="_blank">
                                                                <img
                                                                    // onClick={() => setFullscreenImage({open: true, image: marker.image})}
                                                                    style={{position: "absolute", width: "100%", height: "100%", top: "0", left: "0"}}
                                                                    src={marker.image}
                                                                    alt="pit"
                                                                />
                                                            </Link>
                                                        </Box>
                                                        <Box className="flex gap-[.25rem] flex-col">
                                                            <Box className="flex items-center gap-[.75rem]">
                                                                <p className="text-[#6B7280]">{t("latitude")}:</p>
                                                                <p className="font-semibold text-base">{parseFloat(marker.latitude).toFixed(5)}</p>
                                                            </Box>
                                                            <Box className="flex items-center gap-[.75rem]">
                                                                <p className="text-[#6B7280]">{t("longitude")}:</p>
                                                                <p className="font-semibold text-base">{parseFloat(marker.longitude).toFixed(5)}</p>
                                                            </Box>
                                                            { marker.repaired_by &&
                                                                <Typography fontWeight={600} sx={{margin: ".5rem 0"}}>
                                                                    {marker.repaired_by}
                                                                </Typography>
                                                            }
                                                            <Typography fontWeight={500}
                                                                sx={{
                                                                    fontSize: "14px",
                                                                    mb: ".5rem",
                                                                    color: marker.status === "Ожидает"
                                                                        ? "#D03801"
                                                                        : marker.status === "В работе"
                                                                            ? "#047481"
                                                                            : marker.status === "На проверке"
                                                                                ? "orange"
                                                                                : "#1C64F2"
                                                                }}
                                                            >
                                                                {marker.status}
                                                            </Typography>
                                                            <Box
                                                                fontWeight={500}
                                                                className="flex w-fit gap-[.5rem] items-center mb-[.5rem]"
                                                            >
                                                                { marker.level }
                                                                { marker.level === "Маленькая яма"
                                                                    ? <PinGreen />
                                                                    : marker.level === "Средняя яма"
                                                                        ? <PinOrange />
                                                                        : marker.level === "Большая яма"
                                                                            ? <PinRed />
                                                                            : null
                                                                }
                                                            </Box>
                                                            <Typography sx={{fontSize: "14px", fontWeight: "500"}}>
                                                                {dayjs(marker.created_at).format("DD MMMM YYYY")}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    { marker.repair_image &&
                                                        <Box sx={{marginTop: "10px"}}>
                                                            <Typography fontWeight={600} sx={{marginBottom: "10px !important"}}>
                                                                {t("referred_files")}
                                                            </Typography>
                                                            <Box sx={{position: "relative", width: "42px", height: "42px", overflow: "hidden", borderRadius: "4px"}}>
                                                                <Link to={marker.image} target="_blank">
                                                                    <img
                                                                        style={{position: "absolute", width: "100%", height: "100%", top: "0", left: "0"}}
                                                                        src={marker.repair_image}
                                                                        alt="pit"
                                                                    />
                                                                </Link>
                                                            </Box>
                                                        </Box>
                                                    }
                                                    { marker.status !== "Исправили" && marker.status !== "На проверке" &&
                                                        <Button
                                                            sx={{width: "100%", mt: "10px", color: "white", backgroundColor: "brandColor.primary", padding: ".5rem 1rem",
                                                                ":hover": {
                                                                    color: "white", backgroundColor: "brandColor.primary",
                                                                    opacity: ".75"
                                                                }
                                                            }}
                                                            onClick={() => onFixedHandler(marker.id, marker.status)}
                                                        >
                                                            {t(getButtonLabel(marker.status))}
                                                        </Button>
                                                    }
                                                    { marker.status === "На проверке" &&
                                                        <Box sx={{display: "flex", gap: ".5rem"}}>
                                                            <Button
                                                                sx={{width: "100%", mt: "10px", color: "white", backgroundColor: "brandColor.primary", padding: ".5rem 1rem",
                                                                    ":hover": {
                                                                        color: "white", backgroundColor: "brandColor.primary",
                                                                        opacity: ".75"
                                                                    }
                                                                }}
                                                                onClick={() => onFixedHandler(marker.id, "Ожидает")}
                                                            >
                                                                {t("decline")}
                                                            </Button>
                                                            <Button
                                                                sx={{width: "100%", mt: "10px", color: "white", backgroundColor: "brandColor.primary", padding: ".5rem 1rem",
                                                                    ":hover": {
                                                                        color: "white", backgroundColor: "brandColor.primary",
                                                                        opacity: ".75"
                                                                    }
                                                                }}
                                                                onClick={() => onFixedHandler(marker.id, "Исправили")}
                                                            >
                                                                {t("accept")}
                                                            </Button>
                                                        </Box>
                                                    }
                                                </Popup>
                                            </Marker>
                                        ))}
                                    </MarkerClusterGroup>
                                </MapContainer>
                            </>
                        ) : isMobile
                            ? (
                                <Box sx={{display: "flex", gap: "1rem", flexDirection: "column"}}>
                                    { rows.map((row, index) => (
                                        <Fragment key={index}>
                                            <Typography sx={{color: "rgba(17, 25, 40, 1)", fontWeight: "500", width: "100%", textAlign: "center"}}>
                                                {dayjs(row.date).format("DD MMMM YYYY")}
                                            </Typography>
                                            { row.data && row.data.length > 0 && row.data.map((data: any, rowIndex: number) => (
                                                <Box key={rowIndex} sx={{display: "flex", flexDirection: "row", gap: ".75rem"}}>
                                                    <Box sx={{position: "relative", width: "140px", height: "140px", borderRadius: "4px", overflow: "hidden"}}>
                                                        <Link to={`${BASE_URL}${data.image}`} target="_blank">
                                                            <LazyLoadImage
                                                                alt="image"
                                                                effect="blur" // Эффект загрузки (например, "blur" или "opacity")
                                                                src={`${BASE_URL}${data.image}`}
                                                                style={{
                                                                    width: "140px",
                                                                    height: "140px"
                                                                }}
                                                            />
                                                        </Link>
                                                    </Box>
                                                    <Box className="flex gap-[.25rem] flex-col">
                                                        <Box className="flex items-center gap-[.75rem]">
                                                            <p className="text-[#6B7280]">{t("latitude")}:</p>
                                                            <p className="font-semibold text-base">{parseFloat(data.latitude).toFixed(5)}</p>
                                                        </Box>
                                                        <Box className="flex items-center gap-[.75rem]">
                                                            <p className="text-[#6B7280]">{t("longitude")}:</p>
                                                            <p className="font-semibold text-base">{parseFloat(data.longitude).toFixed(5)}</p>
                                                        </Box>
                                                        { data.repaired_by &&
                                                            <Typography fontWeight={600} sx={{margin: ".5rem 0"}}>
                                                                {data.repaired_by}
                                                            </Typography>
                                                        }
                                                        <Typography fontWeight={500}
                                                            sx={{
                                                                fontSize: "14px",
                                                                mb: ".5rem",
                                                                color: data.status === "Ожидает"
                                                                    ? "#D03801"
                                                                    : data.status === "В работе"
                                                                        ? "#data"
                                                                        : data.status === "На проверке"
                                                                            ? "orange"
                                                                            : "#1C64F2"
                                                            }}
                                                        >
                                                            {data.status}
                                                        </Typography>
                                                        <Box
                                                            fontWeight={500}
                                                            className="flex w-fit gap-[.5rem] items-center mb-[.5rem]"
                                                        >
                                                            { data.level }
                                                            { data.level === "Маленькая яма"
                                                                ? <PinGreen />
                                                                : data.level === "Средняя яма"
                                                                    ? <PinOrange />
                                                                    : data.level === "Большая яма"
                                                                        ? <PinRed />
                                                                        : null
                                                            }
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Fragment>
                                    ))}
                                </Box>
                            ) : (
                                <DataGrid
                                    sx={{
                                        zIndex: "1001",
                                        border: "none",
                                        ".MuiDataGrid-virtualScrollerRenderZone": {
                                            transform: "none !important"
                                        },
                                        ".MuiDataGrid-cell": {
                                            border: "none",
                                            pl: "0",
                                        },
                                        ".MuiDataGrid-row": {
                                            gap: ".75rem"
                                        },
                                        ".css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                                            gap: ".75rem"
                                        },
                                        ".MuiDataGrid-columnHeader": {
                                            pl: "0"
                                        },
                                        ".MuiDataGrid-columnHeaders": {
                                            border: "none"
                                        },
                                        ".MuiDataGrid-footerContainer": {
                                            border: "none"
                                        },
                                        ".MuiDataGrid-columnHeaderTitle": {
                                            color: "text.secondary"
                                        }
                                    }}
                                    disableVirtualization
                                    getRowSpacing={() => ({ top: 12, bottom: 12 })}
                                    getRowHeight={() => "auto"}
                                    // autoHeight
                                    disableColumnMenu
                                    rows={rows}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 10 },
                                        },
                                        columns: {
                                            columnVisibilityModel: {
                                                id: false,
                                            },
                                        },
                                    }}
                                    pageSizeOptions={[10, 25, 50, 100]}
                                    loading={isLoading}
                                    hideFooterSelectedRowCount
                                    componentsProps={{
                                        pagination: {
                                            labelRowsPerPage: t("rows_per_page")
                                        }
                                    }}
                                />
                            )
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default RoadPits;